import React from "react"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby'

export default function Privacy() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Privacy Policy | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Privacy Policy
            </h2>
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                <p className="text-2xl font-bold text-gray-900 ">
                    TL;DR:
                  </p>

                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  <ol class="list-latin ml-4">
                    <li>We only collect minimum necessary personal data to provide our service. We do not sell or share your data.</li>
                    <li>We collect analytics data including your IP, location and actions on this site to make it better.</li>
                    <li>You are responsible for taking consent of your candidates to be tested on Skillmeter.</li>
                    <li>We have taken technical and operational measures to protect personal data.</li>
                    <li>We will notify you without undue delay in the event of a data breach.</li>
                    <li>For any query in data protection, you can reach us at support@skillmeter.com.</li>

                </ol>
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THIS WEBSITE OR OUR SERVICES. ALGORITHMA SAS ("Company") and www.Skillmeter.com ("the Website") (collectively "we") are committed to protecting the privacy and security of our visitors, registered users, and subscribers who access our Website or Services. This Privacy Policy sets forth how the Website and our Services collect and use personal information. By using the Website and Services, you acknowledge that you have read and accepted our privacy policy set forth below.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  If you do not accept our privacy practices, then you should not continue to use either the Website or the Services. This Privacy Policy is incorporated by reference into and forms part of our <Link to='/terms'  className='underline text-blue-600 hover:text-blue-800'>Terms of Service</Link>.


                  </p>

                </div>


                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    1. Confidentiality and Security of Personal Information
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We have implemented commercially reasonable security measures and follow generally accepted industry standards to protect personally identifiable information sent to our Website or provided to our Services, both during transmission and afterwards. It is your responsibility to ensure that you do not disclose your password to anyone.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  However, despite our efforts to maintain the confidentiality and security of your account, no method of transmission over the Internet and no method of electronic storage is completely secure. Although we make every effort to protect your personal information, we cannot guarantee its absolute security.
                </p>

                </div>
  
                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    2. Personal Information We Collect
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  When you register an account with us, we collect personal information from you. You must provide your name of business, email address, and credit card information (including your three digit security number). You will also be asked to create a login and site name, as well as a password for your account.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  When you access and use our Services, we also collect information about the candidates contacts that you add into your account and any tests that you create. It is your responsability to obtain the consent of your candidates before entering their personal information (first name, last name, email address) in Skillmeter.                    </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We collect the IP address of your computer in order to enable us to detect unauthorized transactions, better manage website traffic, and to gather other analytics on the Website.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We also use "cookies," which are small data files placed and stored on your computer, to help you manage your use of our Website. Cookies are used as follows:
                  <ol class="list-latin ml-4">
                        <li>To recognize you as a regular user;</li>
                        <li>To remind us of who you are;</li>
                        <li>To estimate our audience size by determining repeat usage of the Website to help target advertisements based on user interests and behavior;</li>
                        <li>To track candidates progress;</li>
                        <li>To measure traffic patterns for use as a research tool to understand how our users habits are similar or different from one another; and</li>
                        <li>To conduct other research.</li>
                    </ol>
</p>
                <p className="mt-8 text-lg font-normal text-gray-600 ">
                You can set your browser to notify you when you receive a cookie, in order to give you the chance to determine whether or not to accept it. If you do not accept cookies, some pages may not display properly or you may not be permitted to access certain information.
                </p>
                </div>
  
                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    3. Purposes for Which We Use Personal Information
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We make every effort to identify the purposes for which we will collect personal information at or before the time the information is collected. We will use your personal information only for the purposes disclosed at the time of collection or as otherwise set out in this Privacy Policy, unless we have your consent for another use, or unless such other use is authorized or required by law.
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  Our policy is to collect personal information from you for the following purposes:
                  <ol class="list-latin ml-4">
                        <li>to send test invites to your candidates</li>
                        <li>to provide testing services to your candidates;</li>
                        <li>to generate analytics for the improvement of the Website and Services, and for marketing purposes;</li>
                        <li>to maintain a commercial relationship with you and to communicate with you (eg: account verification, support, etc.);</li>
                        <li>to identify your needs and/or preferences;</li>
                        <li>to meet legal and regulatory requirements;</li>
                        <li>to administer, maintain, manage, and improve our business operations; and</li>
                        <li>as otherwise required by law.</li>
                    </ol>                  
                </p>

                </div>
  
                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    4. Data Protection
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  The terms "Data Subject", "Data Controller", "Personal Data" and "processing" shall have the meanings set out in in GDPR, except to the extent that any personal data or information applies to a California resident, in which case the equivalent and/or additional meanings (including for “Business”, “Service Provider”, and “Resident”) set out in the CCPA, shall apply.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  The parties shall comply with the provisions and obligations imposed on them by the Data Protection Laws at all times when processing Personal Data in connection with this Agreement.
                </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  Skillmeter is the processor of the Personal Data your organization provides in order to invite users to the Skillmeter platform (such as name and email address) (collectively the “Invitation Data”). Skillmeter is the controller of user data, information that is confirmed, inputted, or generated by users on the Skillmeter platform.
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  Skillmeter, in its capacity as a processor of Personal Data, uses the following subprocessors to carry out its processing activities:
                  <table className="w-full border border-black">
                        <tr className="border border-black">
                            <td><b>Subprocessor name</b></td>
                            <td><b>Purpose</b></td>
                            <td><b>Location</b></td>
                        </tr>
                        <tr className="border border-black">
                            <td>Cloudflare</td>
                            <td>Cloud hosting</td>
                            <td>WW</td>
                        </tr>

                        <tr className="border border-black">
                            <td>Microsoft Azure</td>
                            <td>Cloud hosting &amp; services</td>
                            <td>EU</td>
                        </tr>

                        <tr className="border border-black">
                            <td>Amazon Web Services</td>
                            <td>Cloud hosting &amp; services</td>
                            <td>EU</td>
                        </tr>

                        <tr className="border border-black">
                            <td>Stripe</td>
                            <td>Payment processor</td>
                            <td>EU/US</td>
                        </tr>

                        <tr className="border border-black">
                            <td>Google Analytics</td>
                            <td>Analytics service</td>
                            <td>US</td>
                        </tr>

                        <tr className="border border-black">
                            <td>SmartRecruiters</td>
                            <td>Applicants tracking system</td>
                            <td>EU/US</td>
                        </tr>
                    </table>
                </p>
                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    5. Access to Your Personal Information
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  You can review, update, delete, or correct your personal information on file with us online at any time. To access your personal information, we require a written request; unless you login and update your personal information yourself directly. Upon receipt of any written request, we will take commercially reasonable steps to provide you with the opportunity to access any such information. We may charge a reasonable fee in order to cover our costs in providing such access.
                </p>
                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    6. Contact with You
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We may use your email address to contact you from time to time, in order to send notices required by law, to provide information regarding this Website or our Services, or to resolve any complaint.
                </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                  7. Changes and Updates to this Privacy Policy
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  We may amend this Privacy Policy at any time at our sole discretion and without prior notice. You should check back with us from time to time in order to confirm that there have been no significant changes.
                </p>
                <p className="mt-8 text-lg font-normal text-gray-600 ">
                Please note that the use of information that we gather is subject to the Privacy Policy then in effect at the time of use.
                </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    8. Other Legal Notices
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  If you ever have a dispute over privacy or security, it will be subject to this Privacy Policy and our Terms of Service. We will always make every effort to resolve any concerns that you have regarding privacy that arises in connection with this Privacy Policy.
                </p>

                </div>

                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    9. Contact Us
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  If you have any questions or concerns over this Privacy Policy or if you need further information regarding the access to your personal information, or how your personal information is used, you may contact us as listed below:
                <br /><br />
                  ALGORITHMA SAS<br />
                    RCS Registration Number: 882 871 775<br />
                    Address: 2 rue de la Libération, 94130 Nogent sur Marne, France<br />                    
                    Email: support@skillmeter.com

                </p>
                </div>


                <div className="py-8">
                  <p className="text-2xl font-bold text-gray-900 ">
                    10. Effective Date
                  </p>
                  <p className="mt-8 text-lg font-normal text-gray-600 ">
                  This Privacy Policy was last modified on the 7th of January, 2021.


                </p>

                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}